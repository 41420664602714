import React, { useState } from 'react'
import Footer from '../../components/footer'
import { Helmet } from 'react-helmet-async'
import Navbar from '../../components/navbar'
import FloatingButton from '../../components/floating-button'

function Politica() {

    const [translate, setTranslate] = useState(false)

    return (
        <>
            <Helmet>
                <title>MODELE BY CAPITAL STUDIO | Politica de Confidențialitate</title>
                <meta name='description' content='MODELE BY ALEX CAPITAL | Politica de Confidențialitate - Protejarea datelor tale personale este o prioritate pentru noi.' />
                <link rel="canonical" href="/politica-de-confidentialitate" />
            </Helmet>
            <Navbar />
            <FloatingButton />
            <>
                <div className='termeni-main' style={{ display: translate && 'none' }}>
                    <button className='termeni-button' onClick={() => setTranslate(true)}>Translate to english</button>
                    <h2>Politica de Confidențialitate - Modele By Capital Studio</h2>
                    <p>Protejarea datelor tale personale este o prioritate pentru noi. Această politică explică modul în care colectăm, utilizăm și protejăm informațiile atunci când accesezi site-ul nostru. Prin utilizarea acestui site, îți exprimi acordul cu această politică.</p>

                    <div>
                        <h2>1. Ce date colectăm</h2>
                        <p>Când vizitezi site-ul nostru, este posibil să colectăm automat sau prin interacțiunile tale următoarele informații:</p>
                        <ul style={{ marginLeft: '40px' }}>
                            <li><strong>Date tehnice:</strong> adresa IP, tipul browser-ului, sistemul de operare, paginile accesate și durata sesiunii.</li>
                            <li><strong>Date furnizate voluntar:</strong> nume, adresă de email sau alte informații, dacă le oferi prin formularele de contact.</li>
                        </ul>
                    </div>

                    <div>
                        <h2>2. Cum utilizăm datele colectate</h2>
                        <p>Datele colectate sunt utilizate pentru:</p>
                        <ul style={{ marginLeft: '40px' }}>
                            <li>Îmbunătățirea experienței pe site.</li>
                            <li>Răspunsuri la întrebările sau cererile tale trimise prin formularele de contact.</li>
                            <li>Analiza traficului și performanței site-ului.</li>
                        </ul>
                    </div>

                    <div>
                        <h2>3. Cookies și tehnologii similare</h2>
                        <p>Site-ul nostru utilizează cookies pentru a îmbunătăți funcționalitatea și experiența utilizatorilor. Acestea sunt fișiere mici stocate pe dispozitivul tău care ne ajută să:</p>
                        <ul style={{ marginLeft: '40px' }}>
                            <li>Analizăm comportamentul de navigare pe site.</li>
                            <li>Personalizăm conținutul afișat.</li>
                        </ul>
                        <p>Poți dezactiva cookies din setările browser-ului tău, însă acest lucru poate afecta funcționalitatea site-ului.</p>
                    </div>

                    <div>
                        <h2>4. Partajarea datelor</h2>
                        <p>Datele tale nu vor fi partajate cu terți, cu excepția situațiilor în care acest lucru este necesar pentru:</p>
                        <ul style={{ marginLeft: '40px' }}>
                            <li>Respectarea obligațiilor legale.</li>
                            <li>Asigurarea funcționării site-ului prin parteneri de încredere (de exemplu, servicii de găzduire web).</li>
                        </ul>
                        <p>Toți partenerii noștri respectă standardele de protecție a datelor.</p>
                    </div>

                    <div>
                        <h2>5. Securitatea datelor tale</h2>
                        <p>Am implementat măsuri tehnice și organizaționale pentru a proteja datele împotriva accesului neautorizat, pierderii sau alterării. Totuși, reține că nicio metodă de transmitere a datelor nu este complet sigură.</p>
                    </div>

                    <div>
                        <h2>6. Drepturile tale</h2>
                        <p>Conform legislației privind protecția datelor, ai dreptul să:</p>
                        <ul style={{ marginLeft: '40px' }}>
                            <li>Soliciți acces la datele personale pe care le colectăm.</li>
                            <li>Corectezi datele incorecte sau incomplete.</li>
                            <li>Ceri ștergerea datelor, acolo unde este posibil.</li>
                            <li>Te opui utilizării datelor tale în scopuri specifice.</li>
                        </ul>
                        <p>Pentru a-ți exercita aceste drepturi, ne poți contacta utilizând informațiile din secțiunea 8.</p>
                    </div>

                    <div>
                        <h2>7. Modificări ale politicii</h2>
                        <p>Această politică poate fi actualizată periodic. Te încurajăm să verifici această pagină pentru a fi la curent cu modificările.</p>
                    </div>

                    <div>
                        <h2>8. Contact</h2>
                        <p>Dacă ai întrebări despre această politică sau despre modul în care gestionăm datele tale, ne poți contacta la:</p>
                        <ul style={{ marginLeft: '40px' }}>
                            <li><strong>Email:</strong></li>
                            <li><strong>Telefon:</strong> 0784094896</li>
                        </ul>
                        <p>Îți mulțumim pentru încredere și pentru vizita pe site-ul nostru!</p>
                    </div>
                </div>

                <div className='termeni-main' style={{ display: !translate && 'none' }}>
                    <button className='termeni-button' onClick={() => setTranslate(false)}>Tradu în romană</button>
                    <h2>Privacy Policy - Models By Capital Studio</h2>
                    <p>Protecting your personal data is a priority for us. This policy explains how we collect, use, and protect your information when you access our website. By using this site, you agree to this policy.</p>

                    <div>
                        <h2>1. What Data We Collect</h2>
                        <p>When you visit our website, we may automatically or through your interactions collect the following information:</p>
                        <ul style={{ marginLeft: '40px' }}>
                            <li><strong>Technical data:</strong> IP address, browser type, operating system, pages visited, and session duration.</li>
                            <li><strong>Voluntarily provided data:</strong> Name, email address, or other information you provide through contact forms.</li>
                        </ul>
                    </div>

                    <div>
                        <h2>2. How We Use the Collected Data</h2>
                        <p>The collected data is used for:</p>
                        <ul style={{ marginLeft: '40px' }}>
                            <li>Improving the website experience.</li>
                            <li>Responding to your questions or requests submitted through contact forms.</li>
                            <li>Analyzing website traffic and performance.</li>
                        </ul>
                    </div>

                    <div>
                        <h2>3. Cookies and Similar Technologies</h2>
                        <p>Our website uses cookies to enhance functionality and user experience. These are small files stored on your device that help us:</p>
                        <ul style={{ marginLeft: '40px' }}>
                            <li>Analyze browsing behavior on the website.</li>
                            <li>Personalize the displayed content.</li>
                        </ul>
                        <p>You can disable cookies in your browser settings, but doing so may affect the website's functionality.</p>
                    </div>

                    <div>
                        <h2>4. Data Sharing</h2>
                        <p>Your data will not be shared with third parties, except in cases where it is necessary to:</p>
                        <ul style={{ marginLeft: '40px' }}>
                            <li>Comply with legal obligations.</li>
                            <li>Ensure the website's functionality through trusted partners (e.g., web hosting services).</li>
                        </ul>
                        <p>All our partners adhere to data protection standards.</p>
                    </div>

                    <div>
                        <h2>5. Security of Your Data</h2>
                        <p>We have implemented technical and organizational measures to protect your data against unauthorized access, loss, or alteration. However, please note that no method of data transmission is completely secure.</p>
                    </div>

                    <div>
                        <h2>6. Your Rights</h2>
                        <p>Under data protection laws, you have the right to:</p>
                        <ul style={{ marginLeft: '40px' }}>
                            <li>Request access to the personal data we collect.</li>
                            <li>Correct incorrect or incomplete data.</li>
                            <li>Request the deletion of data where possible.</li>
                            <li>Object to the use of your data for specific purposes.</li>
                        </ul>
                        <p>To exercise these rights, you can contact us using the information in section 8.</p>
                    </div>

                    <div>
                        <h2>7. Policy Changes</h2>
                        <p>This policy may be updated periodically. We encourage you to check this page to stay informed about any changes.</p>
                    </div>

                    <div>
                        <h2>8. Contact</h2>
                        <p>If you have questions about this policy or how we manage your data, you can contact us at:</p>
                        <ul style={{ marginLeft: '40px' }}>
                            <li><strong>Email:</strong></li>
                            <li><strong>Phone:</strong> 0784094896</li>
                        </ul>
                        <p>Thank you for your trust and for visiting our website!</p>
                    </div>
                </div>

            </>
            <Footer></Footer>
        </>
    )
}

export default Politica