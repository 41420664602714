import React from 'react'
import './index.css'
import { FaDollarSign } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaKey } from "react-icons/fa";
import { BsHandbagFill } from "react-icons/bs";
import section1 from '../../../assets/section1.jpg'

function Section1() {
    return (
        <div className='section1-main'>
            <section className='section1-section'>
                <h1>Ce oferim</h1>
                <div className='section1-div-container'>
                    <div className='section1-div'>
                        <p><FaDollarSign size={'1.2rem'} style={{ marginRight: '8px' }} />2000$ lunar sau procent de 80% din vânzări la alegere</p>
                        <p><FaMapMarkerAlt size={'1.2rem'} style={{ marginRight: '8px' }} />Locații în centru</p>
                    </div>
                    <div className='section1-div'>
                        <p><FaKey size={'1.1rem'} style={{ marginRight: '8px' }} />Cazare gratuită</p>
                        <p><BsHandbagFill size={'1.2rem'} style={{ marginRight: '8px' }} />Servicii de înfrumusețare</p>
                    </div>
                </div>
            </section>
            <img src={section1} alt='Model' />
        </div>
    )
}

export default Section1