import React from 'react'
import './index.css'
import { FaRegCopyright } from "react-icons/fa6";

function Footer() {
    return (
        <div className='footer-main'>
            <nav className="footer-nav">
                <div className="footer-title">
                    <h1>MODELE BY CAPITAL STUDIO</h1>
                </div>
                <div className="footer-links">
                    <li><a href="/">Acasă</a></li>
                    <li><a href="/galerie">Galerie</a></li>
                    <li><a href="/contact">Contact</a></li>
                    <li><a href="/politica-de-confidentialitate">Politica de confidențialitate</a></li>
                    <li><a href="/termeni-si-conditii">Termeni și condiții</a></li>
                </div>
            </nav>
            <div className='footer-right'>
                <FaRegCopyright />
                <h3>Toate drepturile rezervate MODELEBYCAPITALSTUDIO.COM</h3>
            </div>
        </div>
    )
}

export default Footer