import React from 'react'
import './index.css'
import Whatsapp from '../../assets/whatsapp_icon.png'

function FloatingButton() {
    return (
        <button className='floating-button' onClick={() => window.open("https://wa.me/40784094896", '_blank', 'noopener,noreferrer')}>
            <img src={Whatsapp} alt='Whatsapp' />
        </button>
    )
}

export default FloatingButton