import React, { useState } from 'react'
import './index.css'
import { IoIosMenu } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

function Navbar() {

    const [menu, setMenu] = useState(false)
    const navigate = useNavigate()

    return (
        <>
            <nav className="navbar">
                <div className="navbar-title">
                    <h1 onClick={() => navigate('/')}>MODELE BY CAPITAL STUDIO</h1>
                </div>
                <ul className="navbar-links">
                    <li><a href="/">Acasă</a></li>
                    <li><a href="/galerie">Galerie</a></li>
                    <li><a href="/contact">Contact</a></li>
                </ul>
                <IoIosMenu size={'2.2rem'} className='navbar-menu' style={{ display: menu && 'none' }} onClick={() => setMenu(true)} />
                <IoCloseOutline size={'2.2rem'} className='navbar-menu' style={{ display: !menu && 'none' }} onClick={() => setMenu(false)} />
            </nav>
            <div className='navbar-sidemenu' style={{ display: !menu && 'none' }}>
                <ul className="navbar-sidemenu-links">
                    <li><a href="/">Acasă</a></li>
                    <li><a href="/galerie">Galerie</a></li>
                    <li><a href="/contact">Contact</a></li>
                </ul>
            </div>
        </>
    );

}

export default Navbar