import React from 'react'
import Navbar from '../../components/navbar'
import Hero from './hero'
import Section1 from './section1'
import Section2 from './section2'
import Section3 from './section3'
import Footer from '../../components/footer'
import FloatingButton from '../../components/floating-button'

function Home() {
    return (
        <>
            <Navbar />
            <FloatingButton />
            <Hero />
            <Section1 />
            <Section2 />
            <Section3 />
            <Footer />
        </>
    )
}

export default Home