import React, { useState } from 'react'
import Footer from '../../components/footer'
import { Helmet } from 'react-helmet-async'
import Navbar from '../../components/navbar'
import './index.css'
import FloatingButton from '../../components/floating-button'

function Termeni() {

  const [translate, setTranslate] = useState(false)

  return (
    <>
      <Helmet>
        <title>MODELE BY CAPITAL STUDIO | Termeni și Condiții</title>
        <meta name='description' content='MODELE BY ALEX CAPITAL | Termeni și Condiții - Bine ai venit la Modele By Alex Capital! Apreciem interesul tău de a începe o carieră în modelling alături de noi.' />
        <link rel="canonical" href="/termeni-si-conditii" />
      </Helmet>
      <Navbar />
      <FloatingButton />
      <div className='termeni-main' style={{ display: translate && 'none' }}>
        <button className='termeni-button' onClick={() => setTranslate(true)}>Translate to english</button>

        <h2>Termeni și Condiții - Modele By Capital Studio</h2>
        <p>Bine ai venit la Modele By Capital Studio! Apreciem interesul tău de a începe o carieră în modelling alături de noi. Pentru a ne asigura că experiența ta este cât mai sigură și transparentă, te rugăm să citești și să accepți următorii termeni și condiții.</p>
        <p>Accesând sau utilizând serviciile noastre, confirmi că ai citit, ai înțeles și ești de acord cu acești termeni.</p>

        <div>
          <h2>1. Scopul nostru</h2>
          <p>La Modele By Capital Studio, ne angajăm să oferim un mediu profesional și confidențial în care modelele pot excela în cariera lor de modelling. Respectăm drepturile și nevoile fiecărui model, punând la dispoziție resursele necesare pentru a asigura un succes pe termen lung.</p>
        </div>

        <div>
          <h2>2. Eligibilitate</h2>
          <p>Pentru a colabora cu noi, trebuie să îndeplinești următoarele condiții:</p>
          <ul style={{ marginLeft: '40px' }}>
            <li>Ai împlinit vârsta legală (18 ani).</li>
            <li>Ai oferit informații corecte și veridice despre identitatea ta.</li>
            <li>Înțelegi natura activității desfășurate și consimți să participi în mod voluntar.</li>
          </ul>
        </div>

        <div>
          <h2>3. Confidențialitate</h2>
          <p>Confidențialitatea este prioritară pentru noi:</p>
          <ul style={{ marginLeft: '40px' }}>
            <li>Toate informațiile personale, imaginile și materialele realizate vor fi protejate și utilizate strict în scopurile convenite.</li>
            <li>Este interzisă divulgarea oricăror detalii despre colaborarea cu Modele By Capital Studio către terțe părți fără acordul nostru scris.</li>
          </ul>
        </div>

        <div>
          <h2>4. Drepturi și responsabilități</h2>
          <p><strong>Ale tale:</strong></p>
          <ul style={{ marginLeft: '40px' }}>
            <li>Ești liber/ă să întrerupi colaborarea oricând, respectând condițiile contractuale.</li>
            <li>Deții drepturi asupra propriei imagini, dar consimți ca aceasta să fie utilizată conform termenilor agreați.</li>
          </ul>
          <p><strong>Ale noastre:</strong></p>
          <ul style={{ marginLeft: '40px' }}>
            <li>Ne rezervăm dreptul de a refuza colaborarea în cazul încălcării termenilor și condițiilor.</li>
            <li>Vom asigura un mediu de lucru sigur, fără discriminare sau exploatare.</li>
          </ul>
        </div>

        <div>
          <h2>5. Limitări de responsabilitate</h2>
          <p>Deși oferim sprijin constant, nu putem garanta venituri specifice sau rezultate imediate. Succesul depinde de factori precum implicarea și constanța fiecărui model.</p>
        </div>

        <div>
          <h2>6. Plată și remunerație</h2>
          <p>Detaliile privind remunerația sunt clarificate la începutul colaborării și pot varia în funcție de performanță și alte criterii agreate. Toate plățile efectuate sunt conforme cu înțelegerile contractuale și legislația în vigoare.</p>
        </div>

        <div>
          <h2>7. Rezilierea colaborării</h2>
          <p>Colaborarea poate fi întreruptă de către oricare dintre părți, cu notificare prealabilă, conform contractului semnat. În cazul încălcării grave a termenilor, rezilierea poate fi imediată.</p>
        </div>

        <div>
          <h2>8. Modificări ale termenilor și condițiilor</h2>
          <p>Ne rezervăm dreptul de a modifica acești termeni pentru a ne adapta la cerințele legale sau la schimbările din industrie. Te vom informa în prealabil despre orice actualizare.</p>
        </div>

        <div>
          <h2>9. Legislație aplicabilă</h2>
          <p>Acești termeni sunt guvernați de legislația în vigoare din România. Orice dispută va fi soluționată în instanțele competente.</p>
        </div>

        <p>Îți mulțumim că ne-ai ales! Suntem aici să te sprijinim în călătoria ta către succes. Dacă ai întrebări, echipa noastră îți stă la dispoziție. 0784.094.896</p>
        <p><strong>Echipa Modele By Capital Studio</strong></p>
      </div>

      <div className='termeni-main' style={{ display: !translate && 'none' }}>
        <button className='termeni-button' onClick={() => setTranslate(false)}>Tradu în romană</button>


        <h2>Terms and Conditions - Models By Capital Studio</h2>
        <p>Welcome to Models By Capital Studio! We appreciate your interest in starting an modeling career with us. To ensure that your experience is as safe and transparent as possible, please read and accept the following terms and conditions.</p>
        <p>By accessing or using our services, you confirm that you have read, understood, and agreed to these terms.</p>

        <div>
          <h2>1. Our Purpose</h2>
          <p>At Models By Capital Studio, we are committed to providing a professional and confidential environment where models can excel in their modeling careers. We respect the rights and needs of every model, offering the necessary resources to ensure long-term success.</p>
        </div>

        <div>
          <h2>2. Eligibility</h2>
          <p>To collaborate with us, you must meet the following conditions:</p>
          <ul style={{ marginLeft: '40px' }}>
            <li>You have reached the legal age (18 years old).</li>
            <li>You have provided accurate and truthful information about your identity.</li>
            <li>You understand the nature of the activities involved and voluntarily agree to participate.</li>
          </ul>
        </div>

        <div>
          <h2>3. Confidentiality</h2>
          <p>Confidentiality is a priority for us:</p>
          <ul style={{ marginLeft: '40px' }}>
            <li>All personal information, images, and materials created will be protected and used strictly for the agreed purposes.</li>
            <li>Disclosing any details about your collaboration with Models By Capital Studio to third parties without our written consent is prohibited.</li>
          </ul>
        </div>

        <div>
          <h2>4. Rights and Responsibilities</h2>
          <p><strong>Yours:</strong></p>
          <ul style={{ marginLeft: '40px' }}>
            <li>You are free to end the collaboration at any time, respecting the contractual terms.</li>
            <li>You own the rights to your image but agree to its use according to the agreed terms.</li>
          </ul>
          <p><strong>Ours:</strong></p>
          <ul style={{ marginLeft: '40px' }}>
            <li>We reserve the right to refuse collaboration in case of a breach of the terms and conditions.</li>
            <li>We will ensure a safe working environment, free from discrimination or exploitation.</li>
          </ul>
        </div>

        <div>
          <h2>5. Limitation of Liability</h2>
          <p>Although we provide constant support, we cannot guarantee specific earnings or immediate results. Success depends on factors such as each model's involvement and consistency.</p>
        </div>

        <div>
          <h2>6. Payment and Compensation</h2>
          <p>Details regarding compensation are clarified at the start of the collaboration and may vary based on performance and other agreed criteria. All payments made comply with contractual agreements and current legislation.</p>
        </div>

        <div>
          <h2>7. Termination of Collaboration</h2>
          <p>The collaboration can be terminated by either party with prior notice, as per the signed contract. In cases of serious breaches of the terms, termination may be immediate.</p>
        </div>

        <div>
          <h2>8. Changes to Terms and Conditions</h2>
          <p>We reserve the right to modify these terms to adapt to legal requirements or industry changes. You will be informed in advance about any updates.</p>
        </div>

        <div>
          <h2>9. Applicable Law</h2>
          <p>These terms are governed by the applicable laws of Romania. Any disputes will be resolved in the competent courts.</p>
        </div>

        <p>Thank you for choosing us! We are here to support you on your journey to success. If you have any questions, our team is at your disposal. 0784.094.896</p>
        <p><strong>The Models By Capital Studio Team</strong></p>
      </div>

      <Footer />
    </>
  )
}

export default Termeni