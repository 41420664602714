import React from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Camera1 from '../../../src/assets/camera1.jpg'
import Camera2 from '../../../src/assets/camera2.jpg'
import Hero1 from '../../../src/assets/hero1.jpg'
import Hero2 from '../../../src/assets/hero2.jpg'
import Hero3 from '../../../src/assets/hero3.jpg'
import Hero4 from '../../../src/assets/hero4.jpg'
import Section3 from '../home/section3'
import './index.css'
import { Helmet } from 'react-helmet-async'
import FloatingButton from '../../components/floating-button'

function Galerie() {
    return (
        <>
            <Helmet>
                <title>MODELE BY CAPITAL STUDIO | Galerie</title>
                <meta name='description' content='Galerie MODELE BY CAPITAL STUDIO | Locații | Modele' />
                <link rel="canonical" href="/galerie" />
            </Helmet>
            <Navbar />
            <FloatingButton />
            <div className='galerie-main'>
                <h2>Locații</h2>
                <div className='galerie-images-locatii'>
                    <img src={Camera1} alt='Locație modele' />
                    <img src={Camera2} alt='Locație modele' />
                </div>
                <h2>Modele</h2>
                <div className='galerie-images-modele'>
                    <img src={Hero1} alt='Model' />
                    <img src={Hero2} alt='Model' />
                    <img src={Hero3} alt='Model' />
                    <img src={Hero4} alt='Model' />
                </div>
            </div>
            <Section3 />
            <Footer />
        </>
    )
}

export default Galerie