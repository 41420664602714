import React from 'react'
import './index.css'
import Hero1 from '../../../assets/hero1.jpg'
import Hero2 from '../../../assets/hero2.jpg'
import Hero3 from '../../../assets/hero3.jpg'
import Hero4 from '../../../assets/hero4.jpg'

function Hero() {
    return (
        <div className='hero-main'>
            <div className='hero-left'>
                <h2>Începe-ți cariera în unul dintre cele mai mari studiouri din București</h2>
                <p>
                    Datorită experienței noastre în domeniul de modelling,
                    vei avea puse la dispoziție toate instrumentele necesare pentru
                    a deveni un model de top.
                </p>
                <button onClick={() => window.open("https://wa.me/40784094896", '_blank', 'noopener,noreferrer')}>
                    Contact
                </button>
                <div className='hero-images'>
                    <img src={Hero2} alt='Model' />
                    <img src={Hero3} alt='Model' />
                    <img src={Hero4} alt='Model' />
                </div>

            </div>
            <div className='hero-right'>
                <img src={Hero1} alt='Model' />
            </div>
        </div>
    )
}

export default Hero